<template>
  <div class="index">
    <!-- <van-nav-bar
      title="标题"
      left-text="返回"
      left-arrow
    /> -->

    <div class="return">
      <div style="background: white; padding: 3vw 0">
        <img src="@/assets/return.png" @click="toReturn" />
        <div>打卡记录</div>
      </div>
      <div class="search" style="margin-top: 1px">
        <van-search
          @input="onSearch"
          v-model="people_name"
          placeholder="请输入姓名查询"
        />
      </div>
    </div>
    <div style="height: 25vw"></div>
    <img src="@/assets/bg3.png" class="bg" />
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list
        v-model="loading"
        :finished="finished"
        @load="onLoad"
      >
        <div
          class="list-item flex align between"
          v-for="(item, index) in lists"
          :key="index"
        >
          <img :src="item.clockin_image" />
          <div class="item-info">
            <div>{{ item.people.name }}</div>
            <div>打卡时间段：{{ item.time_range_text }}</div>
            <div>打卡时间：{{ item.clockin_time }}</div>
          </div>
        </div>
      </van-list>
    </van-pull-refresh>
    <div class="zan" v-if="zanS">{{ zan }}</div>
  </div>
</template>
<script>
import { clockinLists } from "@/api/apiData";
import { Toast, Loading, PullRefresh, List, NavBar, Search } from "vant";

export default {
  components: {
    [Toast.name]: Toast,
    [NavBar.name]: NavBar,
    [Loading.name]: Loading,
    [PullRefresh.name]: PullRefresh,
    [List.name]: List,
    [Search.name]: Search,
  },
  data() {
    return {
      banner: "",
      zan: "暂无数据",
      zanS: false,
      zanS2: false,
      zanS3: false,
      page: 0, //当前页码
      offset: 16, //当前页面条目数
      totalPages: 1, //总页码
      lists: [], //文章数组
      loading: false,
      finished: false,
      refreshing: false,
      notice: "",
      people_name: "",
    };
  },
  mounted() {
    // 解决滚动条与下拉的冲突
    this.$nextTick(() => {
      const vantList = document.querySelector(".van-list");
      this.resolveConflict(vantList);
    });
  },
  methods: {
    resolveConflict(val) {
      const vantList = val;
      vantList.addEventListener("touchmove", () => {
        this.scrollTop = vantList.scrollTop;
      });
      vantList.addEventListener("touchend", () => {
        this.scrollTop = vantList.scrollTop;
      });
    },
    onSearch(e) {
      var that = this;
      that.onRefresh()
    },
    getList() {
      var that = this;
      that.zanS = false;
      var page = that.page + 1;
      var lists = that.lists;
      var totalPages = that.totalPages;
      var offset = that.offset;
      var status = that.status;
      let obj = {
        page: page,
        size: offset,
        people_name: this.people_name,
      };
      console.log("总页码：" + totalPages);
      console.log("当前页" + page);
      if (totalPages >= page) {
        clockinLists(obj).then((res) => {
          if (res.code == 1) {
            that.page = page;
            that.lists = lists.concat(res.data.list);
            that.totalPages = Math.ceil(
              Number(res.data.total) / res.data.list.length
            );
            if (res.data.list.length == 0) {
              if (status == "") {
                that.zanS = true;
              }
              if (status == 0) {
                that.zanS2 = true;
              }
              if (status == -1) {
                that.zanS3 = true;
              }
              //数据请求成功 但是无数据
              that.finished = true;
              that.loading = false;
              return false;
            }
            if (that.lists.length >= lists.length) {
              // 数据请求成功后  停止加载状态
              setTimeout(() => {
                that.loading = false;
              }, 1500);
            }
          }
        });
      } else {
        // 全部数据加载完毕
        that.finished = true;
      }
    },
    // 上拉记载
    onLoad() {
      setTimeout(() => {
        this.getList();
      }, 500);
    },
    // 下拉刷新
    onRefresh() {
      var that = this;
      that.lists = [];
      that.page = 0;
      that.offset = 16;
      that.totalPages = 1;
      that.loading = true;
      that.finished = false;
      that.getList();
      setTimeout(() => {
        that.refreshing = false;
      }, 1500);
    },
    toReturn() {
      this.$router.go(-1);
    },
  },
};
</script>
<style lang="less" scoped>
.index {
  .bg {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;
  }
  .return {
    position: fixed;
    // background: white;
    width: 100%;
    color: #ff690e;
    font-size: 42px;
    z-index: 2;
    // padding: 30px 0;
    text-align: center;
    font-weight: bold;
    img {
      width: 7vw;
      position: absolute;
      left: 40px;
    }
  }
  .tit_img {
    text-align: center;
    margin: 88px 0 118px;
    // img {
    //   width: 600px;
    // }
  }
  .list-item {
    margin: 2vw 5vw;
    font-size: 3.2vw;
    border: solid 1px #ff690e;
    padding: 1vw;
    img {
      width: 16vw;
      height: 12vw;
      object-fit: cover;
    }
    .item-info {
      width: 80%;
      div:nth-child(1) {
        font-weight: bold;
        margin-bottom: 1.5vw;
        color: #cb4c1e;
      }
      div:nth-child(2) {
        font-size: 2.5vw;
        color: gray;
      }
      div:nth-child(3) {
        font-size: 2.5vw;
        color: gray;
      }
    }
  }
}
</style>